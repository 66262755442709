// import { Link, useLocation } from "react-router-dom";
import { connect } from "../data/content";
import "./Layout.css";
import "../App.css";
import "../pages/Home";

function Layout({ children }) {
  // const location = useLocation();
  // const { pathname } = location;
  // const splitLocation = pathname.split("/");

  return (
    <>
      <header className="Layout__header">
        <h1>
          {/* <a> info@evatkautz.com</a> */}
          Eva Tkautz
        </h1>
        {/* <nav>
          <ul className="Layout__navigation__links">
            <li
              className={`Layout__navigation__link ${
                splitLocation[1] === "" ? "selected" : ""
              } `}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`Layout__navigation__link ${
                splitLocation[1] === "work" ? "selected" : ""
              } `}
            >
              <Link to="/work">Work</Link>
            </li>
          </ul>
        </nav> */}
      </header>
      <main>{children}</main>
      <footer className="Layout__footer">
        <div className="content__group">
          <div className="content__item">
            <ul className="Layout__footer__links">
              {connect.map((item) => (
                <li className="Layout__footer__link">
                  {item && (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.channel}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="content__group">
          <div className="Layout__footer__content content__item content__item--compact">
            <p className="Layout__footer__copyright">
              © {new Date().getFullYear()} Eva Tkautz
            </p>
            {/* <div className="Layout__footer__sustainable-blurb">
              <p>10kg of CO2 saved</p>
              <p>0</p>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Layout;
