// import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "../src/components/Layout";
import Home from "./pages/Home.js";
// import Work from "./pages/Work";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Layout>
        <Home/>
        {/* <Switch>
          <Route exact path="/">
            <Home />
          </Route>{" "}
          <Route exact path="/work">
            <Work />
          </Route>
          <Redirect to="/">
            <Home />
          </Redirect>
        </Switch> */}
      </Layout>
    </div>
  );
}

export default App;
