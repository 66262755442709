const education = [
  "Typography, Royal College of Art, 2021",
  "UX Design, Hyper Island, 2017",
  "Master’s in Media, Human–Computer Interaction focus, London School of Economics, 2014 – 2015",
];

const clients = [
  "Floom",
  "New Edge Magazine",
  "Stylist Magazine",
  "SMARTY",
  "Qubit",
  "British Gas",
  "The New Craftsmen",
];

const work = [
  {
    id: 1,
    name: "SuperHi Plus",
    link: "https://superhi.plus/",
    role: ", Design lead, brand design",
  },
  {
    id: 2,
    name: "Course series: Generative Art",
    link: "https://learngenerative.art/",
    role: ", Senior product designer",
  },
  {
    id: 3,
    name: "Bounties",
    link: "https://bounties.sh/",
    role: ", Senior product designer",
  },
  {
    id: 4,
    name: "Cards for Humanity",
    link: "https://cardsforhumanity.idean.com/",
    role: ", Design lead, developer",
  },
  {
    id: 5,
    name: "Cards for Humanity Figma plugin",
    link: "https://www.figma.com/community/plugin/896347534161970744/Cards-for-Humanity",
    role: ", Design lead, developer",
  },
  {
    id: 6,
    name: "Universal Score",
    link: "https://universalscore.global/",
    role: ", Product designer, developer",
  },
  // {
  //   id: 4,
  //   name: "Stylist Group",
  //   link: "https://www.thestylistgroup.com/",
  //   role: ", Interaction Designer, Developer",
  // },
  // {
  //   id: 5,
  //   name: "Adaptive Lab",
  //   link: "https://adaptivelab.com/",
  //   role: ", Product Designer, Developer",
  // },
  // {
  //   id: 6,
  //   name: "Green Web Manifesto",
  //   role: ", Product Designer, Creative Developer",
  // },
];

// const awards = [
//   "Awwwards Mobile Excellence",
//   "Awwwards Honorable Mention",
//   "CSS Design Awards Best UI",
//   "CSS Design Awards Best UX",
//   "CSS Design Awards Best Innovation",
//   "CSS Design Awards Special Kudos",
//   "FWA Nominee",
//   "Siteinspire",
//   "The Drum Design for Impact Award",
//   "The Drum Chair's Award",
//   "The Drum Highly Commended in Website Design",
// ];

const awards = [
  {
    id: 1,
    name: "SuperHi Plus on Godly",
    link: "https://godly.website/website/955-superhi-plus",
  },
  {
    id: 2,
    name: "SuperHi Plus got Awwwards Honorable Mention",
    link: "https://www.awwwards.com/sites/superhi-plus",
  },
  {
    id: 3,
    name: "SuperHi Plus on SiteInspire",
    link: "https://www.siteinspire.com/websites/11439-superhi-plus",
  },
  {
    id: 4,
    name: "SuperHi Plus on One Page Love",
    link: "https://onepagelove.com/superhi-plus",
  },
  {
    id: 5,
    name: "SuperHi Plus on Curated Design",
    link: "https://www.curated.design/articles/superhi-plus",
  },
  {
    id: 6,
    name: "Bounties on SiteInspire",
    link: "https://www.siteinspire.com/websites/11138-bounties",
  },
  {
    id: 7,
    name: "Bounties on One Page Love",
    link: "https://onepagelove.com/bounties",
  },
  {
    id: 8,
    name: "Cards for Humanity won The Drum Design for Impact Award",
    link: "https://www.thedrum.com/news/2021/05/20/winners-the-drum-awards-design-2021-announced",
  },
  {
    id: 9,
    name: "Cards for Humanity won The Drum Chair's Award",
    link: "https://www.thedrum.com/news/2021/05/20/winners-the-drum-awards-design-2021-announced",
  },

  {
    id: 10,
    name: "Cards for Humanity won Notable Interaction Award",
    link: "https://designawards.core77.com/Interaction/106530/Cards-for-Humanity",
  },
];

const features = [
  {
    id: 1,
    channel: "Figma",
    link: "https://www.figma.com/blog/behind-the-plugin-cards-for-humanity/",
  },
  {
    id: 2,
    channel: "It’s Nice That",
    link: "https://www.itsnicethat.com/news/cards-for-humanity-idean-digital-250820",
  },
  {
    id: 3,
    channel: "Stylist Magazine",
    link: "https://www.stylist.co.uk/visible-women/women-in-tech-development-website-shortlist-media-gender-diversity/212864",
  },
  // {
  //   id: 4,
  //   channel: "What Design Can Do",
  //   link: "https://www.whatdesigncando.com/stories/cards-for-humanity/",
  // },
];

const connect = [
  { id: 1, channel: "read.cv", link: "https://read.cv/evatkautz" },
  { id: 2, channel: "Linkedin", link: "https://www.linkedin.com/in/evatkautz" },
  {
    id: 3,
    channel: "Instagram",
    link: "https://www.instagram.com/evaduboko/",
  },
  { id: 4, channel: "Are.na", link: "https://www.are.na/eva-tkautz" },
  { id: 5, channel: "Twitter", link: "https://twitter.com/EvaTkautz" },
];

const writing = [
  {
    id: 1,
    title: "Want to create tech for good? Design systems can help",
    link: "https://medium.com/ideas-by-idean/want-to-create-tech-for-good-design-systems-can-help-64679fe82c53",
  },
  {
    id: 2,
    title:
      "Get out of your bubble! How we designed Cards for Humanity, an inclusive design tool",
    link: "https://medium.com/ideas-by-idean/get-out-of-your-bubble-how-we-designed-cards-for-humanity-an-inclusive-design-tool-e486e20b9626",
  },
];

const speaking = [
  {
    id: 1,
    title: "Synesthesia podcast, with designer Nejc Prah",
    link: "https://open.spotify.com/episode/3zmkmKP503MjzQaXsSt58A?si=cef8b275c64344a0",
  },
  {
    id: 2,
    title:"The Future of Music panel, with She Can Code",
  },
  {
    id: 3,
    title: "Tech for Good talk, at Pi People",
  },
];

const peopleSay = [
  "  “This plugin [Cards for Humanity] is top notch on so many levels! I've been using as an inspiration for creating internal empathy cards as well.”",
];

export {
  education,
  clients,
  work,
  awards,
  features,
  connect,
  writing,
  speaking,
  peopleSay,
};