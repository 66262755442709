import {
  education,
  clients,
  work,
  awards,
  features,
  writing,
  peopleSay,
  speaking,
} from "../data/content";
import "./Home.css";
import "../App.css";

function Home() {
  return (
    <div className="Home__content__wrapper">
      <div className="content__group">
        <div className="content__item h-170">
          <p className="Home__bio__parapgraph">
            Eva is a Slovenian product designer based in London. Inspired by her
            life in 5 countries, she loves creating products for people and the
            planet. Previously, she created tools to help people think and
            design more inclusively – from designers, teachers, to companies
            like Figma. She also created tools to help creatives make a
            sustainable living.
          </p>
          <p>
            Eva has development experience and is a photographer, exploring expression and humour.
          </p>
        </div>
        <div className="content__item h-120">
          <h3>Education</h3>
          <ul>
            {education.map((item) => (
              <li>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="content__item">
          <div className="Home__portrait content__items" />
        </div>
      </div>
      <div className="content__group">
        <div className="content__item h-170">
          <h3>Selected work</h3>
          <ul>
            {work.map((item) => {
              return (
                <li>
                  {item && item.link ? (
                    <p>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                      <span>{item.role} </span>
                    </p>
                  ) : (
                    <p>
                      {item.name} <span>{item.role}</span>
                    </p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content__item h-120">
          {" "}
          <h3>People say</h3>
          <ul>
            {peopleSay.map((item) => (
              <li>
                <p>{item}</p>
              </li>
            ))}
          </ul>
          <p className="mt-4">– User Experience Researcher at Figma</p>
        </div>
        <div className="content__item">
          <h3>Selected clients</h3>
          <ul>
            {clients.map((item) => (
              <li>
                <p>{item}</p>
              </li>
            ))}
          </ul>
          <p className="mt-12">
            Designed products in media, education, web3, software, mobile networks,
            fashion, ecommmerce, healthcare, finance, and energy.{" "}
            <a href="mailto:hi@evatkautz.com" target="_blank" rel="noopener noreferrer">
              Contact me
            </a>{" "}
            for more info.
          </p>
        </div>
        <div className="content__item">
          {" "}
          <h3>Awards</h3>
          <ul>
            {awards.map((item) => (
              <li>
                <p className="mb-4">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.name}
                  </a>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="content__group">
        <div className="content__item h-170">
          <h3>Features</h3>
          <ul>
            {features.map((item) => {
              return (
                <li>
                  {item && item.link ? (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.channel}
                    </a>
                  ) : (
                    <p>{item.channel}</p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content__item h-120">
          <h3>Speaking</h3>
          <ul>
            {speaking.map((item) => {
              return (
                <li>
                  {item && item.link ? (
                    <p className="mb-4">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    </p>
                  ) : (
                    <p>{item.title}</p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content__item">
          <h3>Writing</h3>
          <ul>
            {writing.map((item) => (
              <li>
                <p className="mb-5">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;